.ant-menu-item.top-menu.ant-menu-item-selected {
  background-color: #455463 !important;
}

.dynamic-delete-button {
  cursor: pointer;
  color: #999;
  transition: all 0.3s;
  margin-left: 10px;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}